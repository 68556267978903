<template>
  <v-container>
    <base-header>
      <div slot="page-header">
        <h2 class="font-weight-black primary--text">User Devices</h2>
      </div>
    </base-header>
    <v-data-table v-model="selected"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  :items="items"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[10,30,50],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  :class="[selected && 'selected']"
                  class="elevation-1">
    </v-data-table>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: 'user-devices',
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetch()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetch()
    },
    fetch() {
      console.log('this.userId',this.userId)
      if (this.userId) {
        this.loading = true;
        let url = process.env.VUE_APP_BASE_API_URL + '/admin/user/device/' + this.userId;
        axios.get(url, {
          params: {
            page: this.pagination.page - 1,
            size: this.pagination.rowsPerPage,
          }
        }).then(resp => {
          this.items = resp.data.content;
          this.pagination.totalItems = resp.data.totalElements
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },

  created() {
    this.$watch(
        () => this.userId,
        (toValue, previousValue) => {
          if (toValue !== previousValue) {
            this.fetch()
          }
        }
    )
    this.fetch()
  },
  props: {
    userId: {
      type: String | null,
      default: () => null,
    },
  },
  data() {
    let data = {
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      loading: false,
      items: [],
      selected: [],
      userId: null,
      headers: [
        {
          text: 'UID',
          sortable: false,
          value: 'uid',
        },
        {
          text: 'Firebase token',
          sortable: false,
          value: 'firebasePushToken'
        },
      ],
    }
    return data;
  },
};
</script>
