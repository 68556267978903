<template>
  <div v-if="user">
    <v-container>
      <base-header>
        <div slot="page-header">
          <div class="d-flex align-center">
            <back-button></back-button>
            @{{ user.userInfo.nickname }}
          </div>
        </div>
        <div slot="page-search">
          <div class="d-flex justify-end">
            <v-btn depressed dark color="primary"
                   @click="showPasswordResetConfirm=true"
            >
              SEND RESET PASSWORD
            </v-btn>
            <v-btn depressed dark color="success"
                   class="ml-4"
                   v-if="user.status==='CREATED'"
                   @click="showConfirmEmailConfirm=true"
            >
              SEND EMAIL CONFIRM
            </v-btn>
            <v-btn depressed dark color="error"
                   class="ml-4"
                   v-if="user.status!=='BLOCK'"
                   @click="showBanUserConfirm=true"
            >
              BLOCK
            </v-btn>
            <v-btn depressed dark color="success"
                   class="ml-4"
                   v-if="user.status!=='VERIFIED'"
                   @click="showVerifyUserConfirm=true"
            >
              VERIFY
            </v-btn>
          </div>

        </div>
      </base-header>
      <v-row>
        <v-col class="col-12 col-md-12">
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar size="128">
                <v-img style="width:128px;height:128px;background: #616161"
                       :src="user.userInfo.avatarUrl || require('../../assets/images/users/2.png')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.userInfo.name || '-' }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span style="display: block;font-size: 10px" class="mt-2">Email</span>
                  <a :href="`mailto:${user.email}`" style="display: block;font-size: 12px">{{ user.email }}</a>
                  <span style="display: block;font-size: 10px" class="mt-2">Role</span>
                  {{ user.role }}
                  <v-btn elevation="2"
                         v-if="user.id!==currentUserId"
                         icon
                         x-small
                         depressed
                         outlined
                         color="primary"
                         raised
                         rounded
                         @click="showChangeUserRole=true"
                         class="ml-2">
                    <v-icon
                        color="primary"
                        x-small
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4 mr-8">
                      RAELICS
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ user.tokensCount }}
                    </v-list-item-title>

                  </v-list-item-content>
                  <v-list-item-content>
                    <div class="text-overline mb-4 mr-8">
                      WALLETS
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ user.walletsCount }}
                    </v-list-item-title>

                  </v-list-item-content>
                  <v-list-item-content>
                    <div class="text-overline mb-4 mr-8">
                      Followers
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ user.followersCount }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      Following
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ user.followingCount }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-action>

            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-tabs v-model="tab" background-color="primary" centered dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        PROFILE
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        RAELICS
        <v-icon>mdi-star</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        FOLLOWERS
        <v-icon>mdi-account-box</v-icon>
      </v-tab>

      <v-tab href="#tab-4">
        FOLLOWING
        <v-icon>mdi-account-box</v-icon>
      </v-tab>

      <v-tab href="#tab-5">
        DEVICES
        <v-icon>mdi-cellphone</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <v-list two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>BIO</b>
                    </v-list-item-title>
                    <v-list-item-content>
                      {{ user.userInfo.description || '-' }}
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <tokens :filterByUserId="user.id"></tokens>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <users :by-user-id-followers="user.id"></users>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item value="tab-4">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <users :by-user-id-following="user.id"></users>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <v-tab-item value="tab-5">
        <v-container>
          <v-row no-gutters>
            <v-col class="col-12 col-md-12">
              <user-devices :user-id="user.id"/>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="showChangeUserRole" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Select the new user role for {{ user.userInfo.nickname }}
        </div>

        <v-select
            v-model="selectedRole"
            :items="[{id:'ADMIN', label:'Admin'},{id: 'USER', label:'User'}, {id:'MODERATOR', label:'Moderator'}]"
            item-text="label"
            item-value="id"
            label="Role"
        ></v-select>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showChangeUserRole=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="setUserRole()">
          Assign
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showBanUserConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to block {{ user.userInfo.nickname }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showBanUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="banUser()">
          BLOCK
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showPasswordResetConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to send reset password to {{ user.email }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showPasswordResetConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="sendResetPassword()">
          SEND
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showVerifyUserConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="blue-grey darken-3"
               :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to set as verified {{ user.userInfo.nickname }}?
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showVerifyUserConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="success" plain @click="verifyUser()">
          Confirm
        </v-btn>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showConfirmEmailConfirm" width="300" v-if="user">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" color="blue-grey darken-3"
               :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to send email verification to {{ user.email }}?
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showConfirmEmailConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="success" plain @click="sendVerifyEmail()">
          Send
        </v-btn>
      </v-sheet>
    </v-dialog>
  </div>

</template>

<script>
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";
import BackButton from "@/components/Common/BackButton";
import Tokens from "@/views/tables/Tokens";
import Users from "@/views/tables/Users";
import UserDevices from "@/views/tables/UserDevices";

export default {
  name: "User",
  components: {
    UserDevices,
    BackButton,
    BaseHeader,
    Tokens,
    Users
  },

  computed: {},
  methods: {
    setUserRole() {
      this.loading = true;
      axios.put(process.env.VUE_APP_BASE_API_URL + `/admin/user/change_role?userId=${this.user.id}&role=${this.selectedRole}`).then(resp => {
        this.fetchUser(this.user.id)
        this.showChangeUserRole = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    fetchTokens() {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/user/${this.user.id}/token`).then(resp => {
        this.tokens = resp.data.content;
      }).finally(() => {
        this.loading = false;
      })
    },
    fetchUser(userId) {
      this.loading = true;
      axios.get(process.env.VUE_APP_BASE_API_URL + `/admin/user/${userId}`).then(resp => {
        this.user = {
          ...resp.data,
          userInfo: {
            ...resp.data.userInfo
          },
        }
        this.selectedRole = this.user.role
      }).finally(() => {
        this.loading = false;
      })
    },
    banUser() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/user/ban_user?userId=' + this.user.id).then(resp => {
        this.fetchUser(this.user.id)
        this.showBanUserConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    sendResetPassword() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/restore_password?email=' + this.user.email).then(resp => {
        this.showPasswordResetConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    sendVerifyEmail() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/user/email_confirm?userId=' + this.user.id).then(resp => {
        this.showConfirmEmailConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    verifyUser() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/user/verify_user?userId=' + this.user.id).then(resp => {
        this.fetchUser(this.user.id)
        this.showVerifyUserConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      this.fetchUser(to.params.user)
    }
    next()
  },
  created() {
    this.fetchUser(this.$route.params.user)
  },
  data() {
    return {
      showBanUserConfirm: false,
      showConfirmEmailConfirm: false,
      showPasswordResetConfirm: false,
      showVerifyUserConfirm: false,
      selectedRole: null,
      currentUserId: this.$store.state.current_user_id,
      tab: null,
      user: null,
      loading: false,
      showChangeUserRole: false,
    }
  }
}
</script>

<style scoped>

</style>
